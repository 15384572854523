import React, { useEffect, useState, lazy, Suspense, memo, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  Chip,
  Divider,
  CircularProgress,
  Alert,
  useTheme,
  alpha,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Step,
  Stepper,
  StepLabel,
  DialogContentText,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Download as DownloadIcon,
  Search as SearchIcon,
  AutoAwesome as AutoAwesomeIcon,
  Rule as RuleIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
  Visibility as VisibilityIcon,
  Assignment as AssignmentIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  Business as BusinessIcon,
  Refresh as RefreshIcon,
  AccountBalance as AccountBalanceIcon,
  Event as EventIcon,
  EventNote as EventNoteIcon,
  Notes as NotesIcon,
  LocationCity as LocationCityIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Project, Permit, Jurisdiction, User, Document as DocumentType, ComplianceCheck, BuildingCode, DocumentComplianceReport } from '../types';
import { projectAPI, permitAPI, jurisdictionAPI, documentComplianceAPI } from '../apis';
import { useDropzone } from 'react-dropzone';

const PERMIT_TYPES = [
  'Building Permit',
  'Electrical Permit',
  'Plumbing Permit',
  'Mechanical Permit',
  'Demolition Permit',
  'Grading Permit',
  'Site Development Permit',
  'Other'
];

// Helper function to get status icon
const getStatusIcon = (status: string) => {
  switch (status) {
    case 'approved':
      return <CheckCircleIcon color="success" />;
    case 'rejected':
      return <CancelIcon color="error" />;
    case 'in_review':
      return <WarningIcon color="warning" />;
    default:
      return <CircularProgress size={20} />;
  }
};

// Helper function to get status chip
const getStatusChip = (status: string) => {
  const statusConfig = {
    approved: { color: 'success' as const, icon: <CheckCircleIcon /> },
    rejected: { color: 'error' as const, icon: <CancelIcon /> },
    in_review: { color: 'warning' as const, icon: <WarningIcon /> },
    compliant: { color: 'success' as const, icon: <CheckCircleIcon /> },
    non_compliant: { color: 'error' as const, icon: <CancelIcon /> },
    pending: { color: 'warning' as const, icon: <WarningIcon /> }
  };
  const config = statusConfig[status.toLowerCase() as keyof typeof statusConfig] || statusConfig.pending;
  return (
    <IconButton
      size="small"
      sx={{
        color: `${config.color}.main`,
        padding: '4px',
        '&:hover': {
          backgroundColor: (theme) => alpha(theme.palette[config.color].main, 0.08)
        }
      }}
    >
      {config.icon}
    </IconButton>
  );
};

const ProjectDetails: React.FC = memo(() => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [jurisdiction, setJurisdiction] = useState<Jurisdiction | null>(null);
  const [owner, setOwner] = useState<User | null>(null);
  const [permits, setPermits] = useState<Permit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Permit creation dialog state
  const [isCreatePermitDialogOpen, setIsCreatePermitDialogOpen] = useState(false);
  const [isPermitDetailsDialogOpen, setIsPermitDetailsDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [permitData, setPermitData] = useState({
    permitType: '',
    estimatedTimeline: '',
  });
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
      setUploadError(null);
    },
    onDropRejected: () => {
      setUploadError('Invalid file type. Please upload PDF, DOC, DOCX, JPG, or PNG files.');
    }
  });

  const handleDialogClose = () => {
    setIsCreatePermitDialogOpen(false);
    setActiveStep(0);
    setPermitData({
      permitType: '',
      estimatedTimeline: '',
    });
    setSelectedFiles([]);
    setUploadError(null);
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPermitData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (!project) return;

    try {
      setError(null);
      setIsSubmitting(true);
      const response = await permitAPI.createPermit(
        {
          ...permitData,
          projectID: project.projectID,
        },
        selectedFiles
      );

      if (response.status === 'success' && response.data) {
        setPermits(prev => [...prev, response.data]);
        handleDialogClose();
      } else {
        throw new Error(response.message || 'Failed to create permit');
      }
    } catch (error) {
      console.error('Error creating permit:', error);
      setError('Failed to create permit. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDocumentClick = async (document: DocumentType) => {
    try {
      setSelectedDocument(document);
      setIsDocumentDialogOpen(true);
      
      // Get signed URL for the document
      const response = await permitAPI.getDocument(document.permitID, document.documentID);
      
      if (response.status === 'success' && response.data) {
        setDocumentUrl(response.data.downloadUrl);
      } else {
        console.error('Error fetching document URL:', response.message);
        setDocumentUrl(null);
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      setDocumentUrl(null);
    }
  };

  const handleViewComplianceReport = (documentId: string) => {
    navigate(`/document-compliance/${documentId}`);
  };

  const handleDocumentDialogClose = () => {
    setSelectedDocument(null);
    setDocumentUrl(null);
    setIsDocumentDialogOpen(false);
  };

  const [selectedPermit, setSelectedPermit] = useState<Permit | null>(null);

  const handlePermitClick = (permit: Permit) => {
    setSelectedPermit(permit);
    setIsPermitDetailsDialogOpen(true);
  };

  const handlePermitDetailsDialogClose = () => {
    setSelectedPermit(null);
    setIsPermitDetailsDialogOpen(false);
  };

  const handleCreatePermitDialogClose = () => {
    setIsCreatePermitDialogOpen(false);
    setActiveStep(0);
    setPermitData({
      permitType: '',
      estimatedTimeline: '',
    });
    setSelectedFiles([]);
    setUploadError(null);
  };

  const [complianceChecks, setComplianceChecks] = useState<ComplianceCheck[]>([]);
  const [buildingCodes, setBuildingCodes] = useState<Record<string, BuildingCode>>({});
  const [loadingCompliance, setLoadingCompliance] = useState(false);
  const [complianceError, setComplianceError] = useState<string | null>(null);
  const [isRunningCheck, setIsRunningCheck] = useState(false);
  const [processingStep, setProcessingStep] = useState(0);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  
  const processingSteps = [
    {
      icon: <SearchIcon />,
      text: "Scanning Documents",
      subtext: "Analyzing project documentation..."
    },
    {
      icon: <AutoAwesomeIcon />,
      text: "AI Analysis",
      subtext: "Processing with advanced AI models..."
    },
    {
      icon: <RuleIcon />,
      text: "Code Verification",
      subtext: "Checking against building codes..."
    },
    {
      icon: <AssignmentTurnedInIcon />,
      text: "Generating Report",
      subtext: "Preparing compliance findings..."
    }
  ];

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunningCheck) {
      interval = setInterval(() => {
        setProcessingStep((prev) => {
          if (prev < processingSteps.length - 1) {
            return prev + 1;
          }
          return 0;
        });
      }, 2000); // Change step every 2 seconds
    } else {
      setProcessingStep(0);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunningCheck]);

  const fetchProjectDetails = useCallback(async () => {
    if (!projectId) return;

    try {
      setLoading(true);
      setError(null);
      setComplianceError(null);

      // Parallelize API calls
      const [projectResponse, complianceResponse, jurisdictionsResponse, permitsResponse] = await Promise.all([
        projectAPI.getProject(projectId),
        projectAPI.getProjectComplianceChecks(projectId),
        jurisdictionAPI.getJurisdictions(),
        permitAPI.getPermits()
      ]);

      // Handle project details
      if (projectResponse.status !== 'success' || !projectResponse.data) {
        throw new Error(projectResponse.message || 'Failed to fetch project details');
      }
      setProject(projectResponse.data);

      // Handle compliance checks
      if (complianceResponse.status === 'success' && complianceResponse.data) {
        setComplianceChecks(complianceResponse.data);
      }

      // Handle jurisdiction details
      if (jurisdictionsResponse.status === 'success' && jurisdictionsResponse.data) {
        const projectJurisdiction = jurisdictionsResponse.data.find(
          j => j.jurisdictionID === projectResponse.data.jurisdictionID
        );
        setJurisdiction(projectJurisdiction || null);
      }

      // Handle permits
      if (permitsResponse.status === 'success' && permitsResponse.data) {
        const projectPermits = permitsResponse.data.filter(permit => permit.projectID === projectId);
        setPermits(projectPermits);
      }
    } catch (error) {
      console.error('Error fetching project details:', error);
      setError('Failed to fetch project details. Please try again later.');
      setComplianceError('Failed to fetch compliance checks.');
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchProjectDetails();
  }, [fetchProjectDetails]);

  const handleRunComplianceCheck = async () => {
    setIsRunningCheck(true);
    setComplianceError(null);
    try {
      const response = await projectAPI.runComplianceCheck(projectId!);
      if (response.status === 'success' && response.data) {
        // Update the UI with the new compliance data
        const complianceData = response.data;
        
        // Convert the new format to match the existing ComplianceCheck type
        const formattedChecks: ComplianceCheck[] = complianceData.permit_analyses.map(analysis => {
          // Determine status based on risk level and issues
          const issues = analysis.analysis_results.analysis.issues || [];
          const hasHighIssues = issues.some((issue: any) => issue.severity === 'high');
          const hasMediumIssues = issues.some((issue: any) => issue.severity === 'medium');
          
          let status;
          if (hasHighIssues) {
            status = 'rejected';
          } else if (hasMediumIssues) {
            status = 'in_review';
          } else {
            status = 'approved';
          }

          return {
            checkID: analysis.check_id || '',
            permitID: analysis.permit_id,
            documentID: analysis.permit_id, // Use permitID since we don't have a separate documentID
            codeID: analysis.permit_id, // Use permitID since we don't have a separate codeID
            status: status,
            issues: JSON.stringify(analysis.analysis_results.analysis), // Store the entire analysis
            checkedDate: new Date(complianceData.timestamp), // Convert string to Date
            checkedByAI: true
          };
        });

        setComplianceChecks(formattedChecks);
        
        // Show success message with summary
        setSuccessMessage(
          `Compliance check completed successfully. Analyzed ${complianceData.total_documents_analyzed} documents across ${complianceData.total_permits_analyzed} permits. Overall risk level: ${complianceData.overall_risk_level}`
        );
        
        // Refresh project details to get updated statuses
        await fetchProjectDetails();
      } else {
        setComplianceError(response.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error running compliance check:', error);
      setComplianceError('Failed to run compliance check');
    } finally {
      setIsRunningCheck(false);
    }
  };

  const renderComplianceIssues = useCallback((issuesStr: string) => {
    const issues = JSON.parse(issuesStr);
    return (
      <List>
        {issues.map((issue: any, index: number) => (
          <ListItem key={issue.id || index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
              <Chip
                label={issue.severity.toUpperCase()}
                color={issue.severity === 'high' ? 'error' : issue.severity === 'medium' ? 'warning' : 'success'}
                size="small"
                sx={{ mr: 1 }}
              />
              <Typography variant="subtitle2" color="text.secondary">
                {issue.category} - {issue.code_reference}
              </Typography>
            </Box>
            <Typography variant="body1">{issue.description}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Remediation: {issue.remediation}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Document: {issue.document_name} | Estimated Effort: {issue.estimated_effort}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  }, []);

  // Add a function to render compliance statistics
  const renderComplianceStats = (stats: { total_issues: number; issues_by_severity: { high: number; medium: number; low: number } }) => {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Statistics</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, textAlign: 'center', borderRadius: '16px' }}>
              <Typography variant="h4">{stats.total_issues}</Typography>
              <Typography variant="body2">Total Issues</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, textAlign: 'center', bgcolor: theme.palette.error.light, borderRadius: '16px' }}>
              <Typography variant="h4">{stats.issues_by_severity.high}</Typography>
              <Typography variant="body2">High Severity</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, textAlign: 'center', bgcolor: theme.palette.warning.light, borderRadius: '16px' }}>
              <Typography variant="h4">{stats.issues_by_severity.medium}</Typography>
              <Typography variant="body2">Medium Severity</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper sx={{ p: 2, textAlign: 'center', bgcolor: theme.palette.success.light, borderRadius: '16px' }}>
              <Typography variant="h4">{stats.issues_by_severity.low}</Typography>
              <Typography variant="body2">Low Severity</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleDeleteProject = async () => {
    if (!project || !window.confirm('Are you sure you want to delete this project?')) {
      return;
    }

    try {
      setError(null);
      const response = await projectAPI.deleteProject(project.projectID);
      
      if (response.status === 'success') {
        navigate('/dashboard');
      } else {
        throw new Error(response.message || 'Failed to delete project');
      }
    } catch (error) {
      console.error('Error deleting project:', error);
      setError('Failed to delete project. Please try again later.');
    }
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'in progress':
        return 'success';
      case 'pending':
        return 'warning';
      case 'complete':
        return 'info';
      default:
        return 'default';
    }
  };

  const getComplianceStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'compliant':
        return {
          bg: theme.palette.success.light,
          color: theme.palette.success.dark,
        };
      case 'non_compliant':
        return {
          bg: theme.palette.error.light,
          color: theme.palette.error.dark,
        };
      default:
        return {
          bg: theme.palette.warning.light,
          color: theme.palette.warning.dark,
        };
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case 'compliant':
        return <CheckCircleIcon sx={{ color: theme.palette.success.main }} />;
      case 'non_compliant':
        return <CancelIcon sx={{ color: theme.palette.error.main }} />;
      default:
        return <WarningIcon sx={{ color: theme.palette.warning.main }} />;
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ p: 2 }}>
            <TextField
              fullWidth
              select
              label="Permit Type"
              name="permitType"
              value={permitData.permitType}
              onChange={handleInputChange}
              required
              sx={{ mb: 2 }}
            >
              {PERMIT_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Estimated Timeline (in weeks)"
              name="estimatedTimeline"
              type="number"
              value={permitData.estimatedTimeline}
              onChange={handleInputChange}
              required
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ p: 2 }}>
            <Box
              {...getRootProps()}
              sx={{
                border: '2px dashed',
                borderColor: 'divider',
                borderRadius: 1,
                p: 3,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: alpha(theme.palette.primary.main, 0.04),
                },
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }} />
              <Typography variant="body1" gutterBottom>
                Drag and drop files here, or click to select files
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supported formats: PDF, DOC, DOCX, JPG, PNG
              </Typography>
            </Box>
            {uploadError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {uploadError}
              </Alert>
            )}
            {selectedFiles.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Selected Files:
                </Typography>
                <List dense>
                  {selectedFiles.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={file.name}
                        secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  const [isAnalyzingDocument, setIsAnalyzingDocument] = useState<string | null>(null);

  const [documentsWithReports, setDocumentsWithReports] = useState<Set<string>>(new Set());

  useEffect(() => {
    const checkExistingReports = async () => {
      if (!permits) return;

      const reportsSet = new Set<string>();
      
      for (const permit of permits) {
        if (!permit.documents) continue;
        
        for (const document of permit.documents) {
          try {
            const response = await documentComplianceAPI.getDocumentComplianceReport(permit.permitID, document.documentID);
            if (response.status === 'success' && response.data) {
              reportsSet.add(document.documentID);
            }
          } catch (error) {
            // If we get a 404, it means no report exists
            // We can ignore this error as it's expected
            console.debug(`No compliance report for document ${document.documentID}`);
          }
        }
      }
      
      setDocumentsWithReports(reportsSet);
    };

    checkExistingReports();
  }, [permits]);

  const handleRunDocumentCompliance = async (document: DocumentType, permitId: string) => {
    try {
      setIsAnalyzingDocument(document.documentID);
      setError(null);
      
      const response = await documentComplianceAPI.analyzeDocumentCompliance(permitId, document.documentID);
      
      if (response.status === 'success' && response.data) {
        // Add the document to the set of documents with reports
        setDocumentsWithReports(prev => new Set(prev).add(document.documentID));
        // Navigate to the compliance report view
        navigate(`/document-compliance/${document.documentID}`);
      } else {
        throw new Error(response.message || 'Failed to analyze document');
      }
    } catch (error) {
      console.error('Error analyzing document:', error);
      setError('Failed to analyze document. Please try again later.');
    } finally {
      setIsAnalyzingDocument(null);
    }
  };

  const [isDocumentsView, setIsDocumentsView] = useState(true);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          pt: '84px',
        }}
      >
        <CircularProgress />
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Loading project details, please wait...
        </Typography>
      </Box>
    );
  }

  if (!project) {
    return (
      <Box sx={{ pt: '84px' }}>
        <Container maxWidth="lg">
          <Alert severity="error">Project not found</Alert>
        </Container>
      </Box>
    );
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <Container maxWidth="xl">
        <Box
          component="main"
          sx={{
            pt: '84px',
            minHeight: '100vh',
            bgcolor: 'background.default',
            pb: 4,
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ mb: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <IconButton
                  onClick={() => navigate(-1)}
                  sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.08),
                      boxShadow: 2,
                    },
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {project?.name}
                  </Typography>
                </Box>
              </Box>

              {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {error}
                </Alert>
              )}

              <Grid container spacing={3}>
                {/* Project Details Card */}
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 3, mb: 3, borderRadius: '16px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Overview
                        </Typography>
                      </Box>
                      <Chip
                        label={project?.status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                        color={
                          project?.status === 'completed' ? 'success' :
                          project?.status === 'in_progress' ? 'warning' : 'default'
                        }
                      />
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <AssignmentIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Project ID
                            </Typography>
                            <Typography variant="body1">
                              {project?.projectID}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <PersonIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Owner ID
                            </Typography>
                            <Typography variant="body1">
                              {project?.ownerID}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <BusinessIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Project Type
                            </Typography>
                            <Typography variant="body1">
                              {project?.projectType}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <RefreshIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Status
                            </Typography>
                            <Typography variant="body1">
                              {project?.status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <AccountBalanceIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Estimated Cost
                            </Typography>
                            <Typography variant="body1">
                              ${project?.estimatedCost.toLocaleString()}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <LocationCityIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Address
                            </Typography>
                            <Typography variant="body1">
                              {project?.address}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <AssignmentIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Parcel Number
                            </Typography>
                            <Typography variant="body1">
                              {project?.parcelNumber}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <NotesIcon sx={{ color: 'text.secondary' }} />
                          <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                              Project Description
                            </Typography>
                            <Typography variant="body1">
                              {project?.description || 'No description provided'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={6}>
                  {/* Compliance Check Card */}
                  <Paper sx={{ p: 3, mb: 3, borderRadius: '16px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6">
                        {isDocumentsView ? 'Compliance Checks' : 'Compliance Check'}
                      </Typography>
                      {!isDocumentsView && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleRunComplianceCheck}
                          disabled={isRunningCheck}
                        >
                          {isRunningCheck ? 'Running Check...' : 'Run Compliance Check'}
                        </Button>
                      )}
                    </Box>
                    
                    {isDocumentsView ? (
                      permits.length > 0 ? (
                        <Table size="medium" sx={{ tableLayout: 'fixed' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell width="40%">Document</TableCell>
                              <TableCell width="30%">Permit Type</TableCell>
                              <TableCell width="15%" align="center">Check</TableCell>
                              <TableCell width="15%" align="center">Report</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {permits.map((permit) => (
                              permit.documents?.map((document) => (
                                <TableRow 
                                  key={document.documentID} 
                                  hover
                                  onClick={() => handleDocumentClick(document)}
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, maxWidth: '100%' }}>
                                      <Box sx={{ minWidth: 0, flexGrow: 1 }}>
                                        <Typography 
                                          variant="body2" 
                                          sx={{ 
                                            fontWeight: 500,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            width: '100%'
                                          }}
                                        >
                                          {document.name}
                                        </Typography>
                                        <Typography 
                                          variant="caption" 
                                          color="text.secondary"
                                          sx={{
                                            display: 'block',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                          }}
                                        >
                                          Version {document.version}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Typography 
                                      variant="body2"
                                      sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                      }}
                                    >
                                      {permit.permitType}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Tooltip title="Run Compliance Check">
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRunDocumentCompliance(document, permit.permitID);
                                        }}
                                        disabled={isAnalyzingDocument === document.documentID}
                                        sx={{ minWidth: 'auto', p: 1 }}
                                      >
                                        {isAnalyzingDocument === document.documentID ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <RuleIcon />
                                        )}
                                      </Button>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell align="center">
                                    {documentsWithReports.has(document.documentID) ? (
                                      <Tooltip title="View Report">
                                        <Button
                                          variant="contained"
                                          size="small"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleViewComplianceReport(document.documentID);
                                          }}
                                          sx={{
                                            backgroundColor: theme.palette.success.main,
                                            '&:hover': {
                                              backgroundColor: theme.palette.success.dark,
                                            },
                                            minWidth: 'auto',
                                            p: 1
                                          }}
                                        >
                                          <VisibilityIcon />
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      <Typography 
                                        variant="body2" 
                                        color="text.secondary"
                                      >
                                        —
                                      </Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <Box sx={{ textAlign: 'center', py: 4 }}>
                          <InsertDriveFileIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                          <Typography variant="body1" color="text.secondary" gutterBottom>
                            No documents found
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Add a permit with documents to get started
                          </Typography>
                        </Box>
                      )
                    ) : (
                      <>
                        {loadingCompliance ? (
                          <Box display="flex" justifyContent="center" p={2}>
                            <CircularProgress size={24} />
                          </Box>
                        ) : complianceError ? (
                          <Alert severity="error">{complianceError}</Alert>
                        ) : isRunningCheck ? (
                          <Box sx={{ width: '100%', py: 3 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mb: 3
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 60,
                                  height: 60,
                                  borderRadius: '50%',
                                  bgcolor: alpha(theme.palette.primary.main, 0.1),
                                  color: 'primary.main',
                                  mb: 2
                                }}
                              >
                                {processingSteps[processingStep].icon}
                              </Box>
                              <Typography variant="h6" color="primary" gutterBottom>
                                {processingSteps[processingStep].text}
                              </Typography>
                              <Typography variant="body2" color="text.secondary" align="center">
                                {processingSteps[processingStep].subtext}
                              </Typography>
                            </Box>
                          </Box>
                        ) : complianceChecks.length === 0 ? (
                          <Typography color="textSecondary" align="center" py={2}>
                            No compliance checks found
                          </Typography>
                        ) : (
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell width="140px">Status</TableCell>
                                  <TableCell>Permit Type</TableCell>
                                  <TableCell>Check Date</TableCell>
                                  <TableCell align="right" width="100px">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {complianceChecks.map((check) => {
                                  const permit = permits.find(p => p.permitID === check.permitID);
                                  return (
                                    <TableRow key={check.checkID}>
                                      <TableCell>
                                        {getStatusChip(check.status)}
                                      </TableCell>
                                      <TableCell>{permit?.permitType || 'Unknown'}</TableCell>
                                      <TableCell>
                                        {new Date(check.checkedDate).toLocaleDateString('en-US', {
                                          month: '2-digit',
                                          day: '2-digit',
                                          year: 'numeric',
                                        })}
                                      </TableCell>
                                      <TableCell align="right">
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          onClick={() => navigate(`/compliance-report/${check.permitID}`)}
                                          sx={{
                                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                                            '&:hover': {
                                              bgcolor: alpha(theme.palette.primary.main, 0.2),
                                            },
                                            whiteSpace: 'nowrap',
                                            minWidth: 'auto'
                                          }}
                                        >
                                          View Report
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </>
                    )}
                  </Paper>

                  {/* Documents and Activities Row */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      {/* Documents Card */}
                      <Paper sx={{ p: 3, borderRadius: '16px' }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Documents ({permits.reduce((acc, permit) => acc + (permit.documents?.length || 0), 0)})
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => setIsCreatePermitDialogOpen(true)}
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                          sx={{
                            bgcolor: '#4169E1',
                            color: '#fff',
                            borderRadius: '100px',
                            px: 4,
                            py: 1.5,
                            mb: 3,
                            '&:hover': {
                              bgcolor: '#3151B7',
                            },
                          }}
                        >
                          Upload
                        </Button>
                        <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                          {permits.some(permit => permit.documents && permit.documents.length > 0) ? (
                            <List sx={{ width: '100%' }}>
                              {permits.map((permit) => 
                                permit.documents?.map((document) => (
                                  <ListItem
                                    key={document.documentID}
                                    onClick={() => handleDocumentClick(document)}
                                    sx={{
                                      p: 2,
                                      mb: 2,
                                      cursor: 'pointer',
                                      borderRadius: 1,
                                      bgcolor: 'background.paper',
                                      '&:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                                      },
                                      '&:last-child': {
                                        mb: 0
                                      }
                                    }}
                                  >
                                    <Box sx={{ 
                                      display: 'flex', 
                                      alignItems: 'center', 
                                      width: '100%',
                                      gap: 2
                                    }}>
                                      <Box sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 40,
                                        height: 40,
                                        borderRadius: 1,
                                        bgcolor: alpha(theme.palette.primary.main, 0.1)
                                      }}>
                                        <InsertDriveFileIcon sx={{ color: 'primary.main', fontSize: 24 }} />
                                      </Box>
                                      <Box sx={{ flex: 1 }}>
                                        <Typography variant="subtitle2" sx={{ mb: 0.5, fontWeight: 500 }}>
                                          {document.name}
                                        </Typography>
                                        <Chip
                                          label={permit.permitType}
                                          size="small"
                                          sx={{
                                            height: 24,
                                            fontSize: '0.75rem',
                                            bgcolor: alpha(theme.palette.grey[500], 0.1),
                                            color: 'text.secondary',
                                            '& .MuiChip-label': {
                                              px: 1.5
                                            }
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </ListItem>
                                ))
                              )}
                            </List>
                          ) : (
                            <Box sx={{ 
                              display: 'flex', 
                              flexDirection: 'column', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              py: 6,
                              bgcolor: alpha(theme.palette.primary.main, 0.04),
                              borderRadius: 2
                            }}>
                              <InsertDriveFileIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                              <Typography variant="body1" color="text.secondary" gutterBottom>
                                No documents uploaded yet
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Upload documents to get started
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {/* Activities Card */}
                      <Paper sx={{ p: 3, borderRadius: '16px' }}>
                        <Typography variant="h6" gutterBottom>
                          Recent Activities
                        </Typography>
                        <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                          <Typography variant="body2" color="text.secondary">
                            No recent activities to display.
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>

                  {/* Document Details Dialog */}
                  <Dialog
                    open={isDocumentDialogOpen}
                    onClose={handleDocumentDialogClose}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogTitle>Document Details</DialogTitle>
                    <DialogContent>
                      {selectedDocument && (
                        <Box sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Document Name
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {selectedDocument.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Type
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {selectedDocument.type}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Version
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {selectedDocument.version}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Upload Date
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {new Date(selectedDocument.uploadDate).toLocaleDateString()}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Size
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {(selectedDocument.size / 1024 / 1024).toFixed(2)} MB
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Status
                              </Typography>
                              <Chip
                                label={selectedDocument.status}
                                color={selectedDocument.status === 'approved' ? 'success' : 'default'}
                                size="small"
                                sx={{ mt: 1 }}
                              />
                            </Grid>
                            
                            {/* Document Preview */}
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Preview
                              </Typography>
                              <Box sx={{ p: 4, textAlign: 'center' }}>
                                <Button
                                  variant="contained"
                                  startIcon={<DownloadIcon />}
                                  onClick={() => documentUrl && window.open(documentUrl, '_blank')}
                                >
                                  Download Document
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDocumentDialogClose}>Close</Button>
                    </DialogActions>
                  </Dialog>

                  {/* Permit Details Dialog */}
                  <Dialog
                    open={isPermitDetailsDialogOpen}
                    onClose={handlePermitDetailsDialogClose}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogTitle>Permit Details</DialogTitle>
                    <DialogContent>
                      {selectedPermit && (
                        <Box sx={{ p: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Permit Type
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {selectedPermit.permitType}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Status
                              </Typography>
                              {getStatusChip(selectedPermit.status)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Submission Date
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {new Date(selectedPermit.submissionDate).toLocaleDateString()}
                              </Typography>
                            </Grid>
                            {selectedPermit.approvalDate && (
                              <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" color="text.secondary">
                                  Approval Date
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                  {new Date(selectedPermit.approvalDate).toLocaleDateString()}
                                </Typography>
                              </Grid>
                            )}
                            {selectedPermit.expirationDate && (
                              <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" color="text.secondary">
                                  Expiration Date
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                  {new Date(selectedPermit.expirationDate).toLocaleDateString()}
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Estimated Timeline
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {selectedPermit.estimatedTimeline} weeks
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" color="text.secondary">
                                Current Review Stage
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {selectedPermit.currentReviewStage}
                              </Typography>
                            </Grid>

                            {/* Documents Section */}
                            <Grid item xs={12}>
                              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                Associated Documents
                              </Typography>
                              {selectedPermit.documents && selectedPermit.documents.length > 0 ? (
                                <List dense>
                                  {selectedPermit.documents.map((doc) => (
                                    <ListItem
                                      key={doc.documentID}
                                      sx={{
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        mb: 1,
                                        '&:hover': {
                                          bgcolor: alpha(theme.palette.primary.main, 0.04),
                                          cursor: 'pointer',
                                        },
                                      }}
                                      onClick={() => handleDocumentClick({
                                        ...doc,
                                        permitID: selectedPermit.permitID
                                      })}
                                    >
                                      <InsertDriveFileIcon sx={{ mr: 1, color: 'primary.main' }} />
                                      <ListItemText
                                        primary={doc.name}
                                        secondary={`Version ${doc.version} | ${new Date(doc.uploadDate).toLocaleDateString()}`}
                                      />
                                      <Chip
                                        label={doc.status}
                                        size="small"
                                        color={doc.status === 'approved' ? 'success' : 'default'}
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              ) : (
                                <Typography variant="body2" color="text.secondary">
                                  No documents attached to this permit
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handlePermitDetailsDialogClose}>Close</Button>
                    </DialogActions>
                  </Dialog>

                  {/* Permit Creation Dialog */}
                  <Dialog
                    open={isCreatePermitDialogOpen}
                    onClose={handleCreatePermitDialogClose}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogTitle>Create New Permit</DialogTitle>
                    <DialogContent>
                      <Stepper activeStep={activeStep} sx={{ py: 3 }}>
                        <Step>
                          <StepLabel>Permit Details</StepLabel>
                        </Step>
                        <Step>
                          <StepLabel>Upload Documents</StepLabel>
                        </Step>
                      </Stepper>
                      {renderStepContent(activeStep)}
                    </DialogContent>
                    <DialogActions>
                      <Button 
                        onClick={handleCreatePermitDialogClose}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                      {activeStep > 0 && (
                        <Button 
                          onClick={handleBack}
                          disabled={isSubmitting}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        onClick={activeStep === 1 ? handleSubmit : handleNext}
                        disabled={
                          isSubmitting ||
                          (activeStep === 0 && (!permitData.permitType || !permitData.estimatedTimeline)) ||
                          (activeStep === 1 && selectedFiles.length === 0)
                        }
                        startIcon={isSubmitting && activeStep === 1 ? <CircularProgress size={20} /> : undefined}
                      >
                        {isSubmitting && activeStep === 1 ? 'Uploading...' : activeStep === 1 ? 'Submit' : 'Next'}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
    </Suspense>
  );
});

export default ProjectDetails; 